import React, { useState } from 'react'
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import showdown from "showdown"
import pdf0 from '../../static/media/candidate-faqs.pdf';
import pdf1 from '../../static/media/Nomination-pack-covering-letter-White-2022.pdf';
import pdf2 from '../../static/media/2.Guidance-for-Candidates-and-Agents-White-2022.pdf';
import pdf3 from '../../static/media/3.NOMINATION-PAPER-new-Yellow-2022.pdf';
import pdf4 from '../../static/media/4.Candidates-Consent-to-Nomination-Yellow-2022.pdf';
import pdf5 from '../../static/media/5.Appointment-of-election-agent-Yellow-2022.pdf';
import pdf6 from '../../static/media/6.Candidates-certificate-of-authorisation-and-request-for-emblem-Yellow-2022.pdf';
import pdf7 from '../../static/media/7.Candidates-request-for-Ward-List-Yellow-2022.pdf';
import pdf8 from '../../static/media/8.Candidates-request-for-absent-voters-List-Yellow-2022.pdf';
import pdf9 from '../../static/media/9.Candidates-consent-to-supply-contact-details-Yellow-2022.pdf';
import pdf10 from '../../static/media/10.Candidate-nomination-checklist-Yellow-2022.pdf';
import pdf11 from '../../static/media/11.Guidance-on-completing-expenses-form-White-2022.pdf';
import pdf12 from '../../static/media/12.Expenses-yellow.pdf';
import pdf13 from '../../static/media/13.Expenses-declaration-candidate-Yellow-2022.pdf';
import pdf14 from '../../static/media/14.Expenses-declaration-agent-Yellow-2022.pdf';
import ReactMarkdown from 'react-markdown';
import Seo from "../components/Seo"
import BecomeCouncillorHero from '../components/BecomeCouncillorHero'
import ModalWindow from '../components/ModalWindow'
import SignUpSection from '../components/SignUpSection';
import CallingBlock from '../components/CallingBlock'

const replaceHeading = (article, heading) => {
  let newArticle = '';
  newArticle = article.replace('<h2>', `<h2 id="${heading.value}">`);
  return newArticle;
}

export default function BecomeACouncillor({ data }) {
  const [ modalOpen, setModalOpen ] = useState(false);
  const [ modalType, setModalType ] = useState('');

  // = = = = = = = = = = = = =
  // Forestry Data
  const pageData = data.page.frontmatter;
  const headings = data.page.headings;
  let article = data.page.html;

  // = = = = = = = = = = = = =
  // Convert Markdown Data to HTML
  const converter = new showdown.Converter();
  const aldermanContent = data.alderman.frontmatter.section_content;
  const aldermanHeadline = data.alderman.frontmatter.section_headline;
  const aldermanDescriptionContent = data.aldermanDescription.frontmatter.section_content;
  const aldermanDescriptionHeadline = data.aldermanDescription.frontmatter.section_headline;
  const councillorContent = data.councillor.frontmatter.section_content;
  const councillorHeadline = data.councillor.frontmatter.section_headline;
  const showCallingBlock = pageData.show_calling_block
  const callingBlockData = data.calling_block.frontmatter
  
  console.log(data.councillor, callingBlockData)

  const aldermanModalData = {
    headline: aldermanHeadline,
    content: aldermanContent,
    ctas: data.alderman.frontmatter.section_call_to_actions
  }

  const aldermanDescriptionModalData = {
    headline: aldermanDescriptionHeadline,
    content: aldermanDescriptionContent,
    ctas: data.aldermanDescription.frontmatter.section_call_to_actions
  }

  const councillorModalData = {
    headline: councillorHeadline,
    content: councillorContent,
    ctas: data.councillor.frontmatter.section_call_to_actions
  }
  
  // = = = = = = = = = = = = =
  // Utils

  // Add id to each Heading with title as a value
  headings.forEach( heading => {
    article = replaceHeading(article, heading);
  });

  // = = = = = = = = = = = = =
  // Event Listeners

  // Set Modal Window Content
  const handleClick = e => {
    e.preventDefault();
    const selectedLink = e.target.id;
    setModalType(selectedLink);
    setModalOpen(true);
  }

  return(
    <>
      <Seo title={ pageData.page_title } />

      <BecomeCouncillorHero 
        headline={ pageData.hero_headline }
        intro={ pageData.hero_intro }
        ctas={ pageData.hero_ctas }
        size="big"
        page="become-a-councillor"
      >
        <StaticImage 
          className="hero__picture"
          src="../../static/media/stand-bg.jpg" 
          alt=""
          placeholder="blurred"
          loading="eager"
        />
        <StaticImage 
          className="hero__picture--absolute"
          src="../../static/media/stand-woman.png" 
          alt=""
          placeholder="blurred"
          loading="eager"
        />
        <StaticImage 
          className="hero__mobile-picture"
          src="../../static/media/dragon-1.png" 
          alt=""
          placeholder="blurred"
          loading="eager"
        />
        <StaticImage 
          className="hero__mobile-picture"
          src="../../static/media/dragon-2.png" 
          alt=""
          placeholder="blurred"
          loading="eager"
        />
      </BecomeCouncillorHero>

      <section className="video-section">
        <div className="container">

          <div className="row">
            <div className="col-md-7 offset-md-1">

              
              <ReactMarkdown className="video-section__intro">{pageData.section_text}</ReactMarkdown>

            </div>
          </div>

          {
            pageData.section_video ?

              <div className="row">
                <div className="col-md-6 offset-md-1">

                  <div className="video-container">
                    <video controls>
                      <source src={ pageData.section_video } type="video/mp4" />
                    </video>
                  </div>

              </div>
            </div>

            : ''
          }
          
        </div>
      </section>

      <div className="page-content page-content--councillor">
        <div className="container">

          <div className="row">
            <div className="col-12">
              <div className="page-content__header">
                <h2 className="page-content__headline">{ pageData.content_headline }</h2>
              </div>
            </div>
          </div>

          <div className="row">

            <div className="col-md-5">
              <StaticImage 
                src="../../static/media/become-a-councillor.png"  
                placeholder="blurred"
                alt=""
              />

            </div>

            <div className="col-md-6">
              <article dangerouslySetInnerHTML={{__html: article }} /> 

              <h2 className="deathline-notification">The next all-out elections for Common Councillor will be held in March 2025. Elections for Aldermen and any Common Councillor by-elections can occur at any time. Register your interest in standing to be kept informed of upcoming vacancies and any events that may be of interest to candidates.</h2>

              <div className="modal-links">
                <h2 className="modal-links__headline">You have to meet certain<br/> qualifications to become a</h2>
                <Link 
                  id="councillor" 
                  className="modal-links__link" 
                  onClick={ (e) => handleClick(e) }
                  to="/"
                >
                    Common Councillor <span className="link__helper">(click to open)</span>
                </Link>
                <h2 className="modal-links__headline">or</h2>
                <Link 
                  id="alderman" 
                  className="modal-links__link" 
                  onClick={ (e) => handleClick(e) } 
                  to="/"
                >
                    Alderman <span className="link__helper">(click to open)</span>
                </Link>

                <div className="button-wrapper">
                  <Link to="/standing-registration" className="button">Register your interest</Link>
                </div>
              </div> 


            </div>
          </div>

          {/* <div className="row">
            <div className="col-12">
              <div className="page-content__header">
                <h2 className="page-content__headline" style={{marginTop: '82px'}}>Nomination Pack for Candidates</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-10">
              <article>
                <ul>
                  <li><Link to="/faq-nomination-candidates">Frequently Asked Questions</Link></li>
                  <li><Link to="/key-dates">Key dates</Link></li>
                </ul>
                <ul>
                  <li><a href={pdf0} download>Candidates FAQs</a></li>
                  <li><a href={pdf1} download>Nomination pack covering letter</a></li>
                  <li><a href={pdf2} download>Guidance for Candidates and Agents (PDF)</a></li>
                  <li><a href={pdf3} download>NOMINATION PAPER (PDF)</a></li>
                  <li><a href={pdf4} download>Candidate's Consent to Nomination (PDF)</a></li>
                  <li><a href={pdf5} download>Appointment of election agent (PDF)</a></li>
                  <li><a href={pdf6} download>Candidates certificate of authorisation and request for emblem (PDF)</a></li>
                  <li><a href={pdf7} download>Candidates request for Ward List (PDF)</a></li>
                  <li><a href={pdf8} download>Candidates request for absent voters List (PDF)</a></li>
                  <li><a href={pdf9} download>Candidates consent to supply contact details (PDF)</a></li>
                  <li><a href={pdf10} download>Candidate nomination checklist (PDF)</a></li>
                  <li><a href={pdf11} download>Guidance on completing expenses form (PDF)</a></li>
                  <li><a href={pdf12} download>Expenses (PDF)</a></li>
                  <li><a href={pdf13} download>Expenses declaration candidate (PDF)</a></li>
                  <li><a href={pdf14} download>Expenses declaration agent (PDF)</a></li>
                </ul>
              </article>


            </div>
          </div> */}

        </div>


      </div>

      
      {
        (modalOpen && modalType === 'alderman') 
        ?  <ModalWindow 
            setModalOpen={setModalOpen} 
            headline={aldermanHeadline} 
            content={aldermanContent} 
            ctas={data.alderman.frontmatter.section_call_to_actions} 
          /> 
        : ''
      }
      {
        (modalOpen && modalType === 'councillor')  
        ?  <ModalWindow 
            setModalOpen={setModalOpen} 
            headline={councillorHeadline} 
            content={councillorContent} 
            ctas={data.councillor.frontmatter.section_call_to_actions} 
          /> 
        : ''
      }

      <div className="page-content page-content--councillor">
        <div className="container">
          
          <div className="row">
            <div className="col-12">
              <div className="page-content__header">
                <h2 className="page-content__headline">What is an Alderman?</h2>
              </div>
            </div>
          </div>

          <div className="row">

            <div className="col-md-5">
              <StaticImage 
                src="../../static/media/councillor.png"  
                placeholder="blurred"
                alt=""
              />

            </div>

            <div className="col-md-6">
              <p>The City of London Alderman is one of the oldest elected roles in the UK. Each of the twenty-five wards elects one Alderman for a term of up to six years and several will see elections in 2022. Together, they form the Court of Aldermen, one of the three governing Courts of the City of London. Their role is to encourage trade, champion standards, build skills in the workforce and promote the City as an attractive place to live and do business.</p>
              <p>Aldermen also sit on the Court of Common Council which is responsible for the overall policies and resources of the City Corporation, and sets its multi-year strategy and plans. They are also appointed to the boards of a range of charities, as well as the governing bodies of the City’s academy schools in neighbouring boroughs. Many Aldermen have a financial or professional services background and are well-placed to develop further the City’s relations with this key sector.</p>
              <p>Each year, the Lord Mayor is elected from amongst the Aldermen - and those looking to stand for the mayoralty will first need to be elected as an Alderman.</p>
              <div className="modal-links">
                <Link 
                  id="alderman-description" 
                  className="modal-links__link" 
                  onClick={ (e) => handleClick(e) } 
                  to="/"
                >
                  Alderman job description <span className="link__helper">(click to open)</span>
                </Link>
              </div>
            </div>
          </div>

          { showCallingBlock === true && 
          <div className='page-content--councillor'>
                    <CallingBlock 
                    title={callingBlockData.block_title} 
                    headline={callingBlockData.block_headline}
                    content={callingBlockData.block_content} 
                    block_call_to_action={callingBlockData.block_call_to_action} 
                    image={callingBlockData.block_image} 
                    /> 
          </div>
          }
        </div>
      </div>
      {
        (modalOpen && modalType === 'alderman-description') 
        ?  
          <ModalWindow 
              setModalOpen={setModalOpen} 
              headline={aldermanDescriptionHeadline} 
              content={aldermanDescriptionContent} 
              ctas={data.alderman.frontmatter.section_call_to_actions} 
            /> 
        : ''
      }


      <SignUpSection />
    </>
  )
}

// = = = = = = = = = = = = =
// GraphQL
export const query = graphql`
  query CouncillorQuery {
    page: markdownRemark(frontmatter: {page_title: {eq: "Become a councillor"}}) {
      frontmatter {
        page_title
        hero_headline
        hero_subhead
        hero_intro
        show_video_in_header
        hero_background_image{
          childImageSharp {
            gatsbyImageData
          }
        }
        hero_background_video
        hero_ctas {
          button_label
          button_url
        }
        content_headline
        show_section_video
        section_text
        section_video
        section_content
        sidebar_links {
          link_label
          link_url
        }
        show_calling_block
      }
      headings(depth: h2) {
        value
      }
      html
    },
    alderman: markdownRemark(
      frontmatter: {requirement_id: {eq: "alderman"}}
    ) {
      frontmatter {
        section_content
        section_headline
        section_call_to_actions {
          button_label
          button_url
        }
      }
    }
    aldermanDescription: markdownRemark(
      frontmatter: {requirement_id: {eq: "alderman-description"}}
    ) {
      frontmatter {
        section_content
        section_headline
        section_call_to_actions {
          button_label
          button_url
        }
      }
    }
    councillor: markdownRemark(
      frontmatter: {requirement_id: {eq: "common-councillor"}}
    ) {
      frontmatter {
        section_content
        section_headline
        section_call_to_actions {
          button_label
          button_url
        }
      }
    }
    calling_block: markdownRemark(
      frontmatter: { block_title: { eq: "Calling block" } }
    ) {
        frontmatter {
            block_title
            block_headline
            block_content
            block_call_to_action {
                button_label
                button_url
            }
            block_image {
                childImageSharp {
                gatsbyImageData
              }
            }
        }
    }
  }
`;